var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-column mid-container-full"},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle,"balanceLoading":_vm.isBalanceLoading,"creditBalance":_vm.creditBalance},on:{"topUpDialog":function($event){return _vm.open('payment')}}})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"center-container d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap animate__animated animate__fadeIn"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1"},[(_vm.pageLoading)?_c('div',{staticClass:"d-flex flex-row"},[_c('ProgressBar',{style:({ height: '10vh' }),attrs:{"size":40,"center":true}})],1):_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 mt-4 mx-2"},[_c('div',{staticClass:"container d-flex flex-column flex-grow-1 align-center flex-shrink-1 mt-1 animate__animated animate__fadeIn",staticStyle:{"padding":"0px !important"}},[_c('img',{staticStyle:{"width":"120px"},attrs:{"src":require("@/assets/images/paper-plane.svg")}}),_c('h2',{staticClass:"ft font-weight-medium pa-1 mt-1"},[_vm._v(" Report Bug ")]),_c('p',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(" Having an issues with any of the pages or the system performance? Report it here. ")])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.reportBug)}}},[_c('div',{staticClass:"d-flex flex-row flex-wrap flex-grow-1 mt-2"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 mx-1 mt-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mr-4 mt-3",class:errors.length > 0
                                ? 'error--text'
                                : 'black--text'},[_vm._v("title")]),_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Title","hint":"Enter your title here","error-messages":errors[0]},model:{value:(_vm.bug.title),callback:function ($$v) {_vm.$set(_vm.bug, "title", $$v)},expression:"bug.title"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 mx-1 mt-2",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('i',{staticClass:"material-icons-outlined mr-2 mt-3",class:errors.length > 0
                                ? 'error--text'
                                : 'black--text'},[_vm._v("description")]),_c('v-textarea',{staticClass:"ft font-weight-medium font-size-sm ml-2",attrs:{"outlined":"","label":"Description","hint":"Enter your description here","error-messages":errors[0]},model:{value:(_vm.bug.content),callback:function ($$v) {_vm.$set(_vm.bug, "content", $$v)},expression:"bug.content"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex fle-row flex-grow-1"},[_c('div',{staticClass:"d-flex flex-row"},[_c('img',{staticStyle:{"width":"55px","height":"55px","border-radius":"50%"},attrs:{"src":_vm.previewUrl
                                ? _vm.previewUrl
                                : 'https://via.placeholder.com/50x50'}})]),_c('ValidationProvider',{staticClass:"d-flex flex-row ml-3 flex-grow-1",attrs:{"tag":"div"}},[_c('v-file-input',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Attach a Bug Screenshot","accept":"image/jpg,image/png,image.jpeg","hint":"Upload an attached screenshot here","prepend-icon":""},on:{"change":_vm.photo}})],1)],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 justify-end"},[_c('v-btn',{attrs:{"type":"submit","loading":_vm.isLoading,"disabled":_vm.isLoading,"color":"primary","depressed":""}},[_c('span',{staticClass:"ft font-weight-medium font-size-sm text-capitalize"},[_vm._v(" Save ")])])],1)])]}}],null,false,1400681354)})],1)])])]):_vm._e()])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }